import type { MetaFunction } from "@remix-run/cloudflare";
import clsx from "clsx";
import { useCallback, useState } from "react";

export const meta: MetaFunction = () => {
  return [
    { title: "Buckets" },
    {
      name: "description",
      content: "Welcome to Buckets!",
    },
  ];
};

const PROMPT = (content: string) => `
You are Categorisation Bot. You help people take things that are written in an unstructured, board format and turn it into clear buckets.

You must follow these parameters:
- always speak in a JSON parseable format with no explanation before or after the JSON content.
- use the following type for each bucket: 
{
  "bucket": "name of bucket...",
  "things": [
    {
      "thing": "the thing the user said",
      "suggestedAction": "give a suggested action. be nice, support the user. Be really human in this. Don't be a full on bot, speak in the tone of a helpful parent or friend."
    }
  ]
}
- upon receiving a user's prompt of their thoughts, goals, concerns, issues, ideas, tasks, todos, literally anything - you will categorise them into simple buckets and using the type above, return a JSON parseable array
- in the JSON array, have a final entry ALWAYS which looks like this:
{
  "bucket": "Encouragement Message",
  "things": [
    {
      "thing": "your encouragement message to this user. It can be as long as you like.  Be really human in this. Don't be a full on bot, speak in the tone of a helpful parent or friend"
    }
  ]
}
- do your best to try and minimise the categories to 6 categories. This will ensure we don't over-burden the user.
- again - your response string must be only the JSON.stringified object. No code snippet of JSON, no other format. ONLY a JSON stringified object. No use of \`\`\`, no use of json\`\`\`, NOTHING except a JSON stringified response string.
  
User:
  ${content}

  Categorisation Bot:
`;

import OpenAI from "openai";
import { Bucket } from "~/mock";

const openai = new OpenAI({
  apiKey: "sk-proj-s4QqaYLHqpqvUSKvmOKAT3BlbkFJGmYFuIUuCMkWr3HhrRx5",
  dangerouslyAllowBrowser: true,
});

const fetchFromGPT = async (content: string) => {
  try {
    const response = await openai.chat.completions.create({
      messages: [
        {
          role: "user",
          content: PROMPT(content),
        },
      ],
      model: "gpt-4o",
    });

    console.log(response.choices[0].message.content);
    let message = response.choices[0].message.content;

    if (message?.includes("```json")) {
      message = message.replace("```json", "");
      message = message.replace("```", "");
    }
    const buckets: Bucket[] = JSON.parse(message ?? "[]");

    console.log(buckets);
    return buckets;
  } catch (e) {
    console.log(e);
  }
};

export default function Index() {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [buckets, setBuckets] = useState<Bucket[]>([]);

  const onClick = useCallback(async () => {
    setLoading(true);
    const response = await fetchFromGPT(content);
    if (response) {
      setBuckets(response);
      const message = document.getElementById("message");
      if (!message) return;

      message?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setLoading(false);
  }, [content]);

  return (
    <div className="w-full min-h-[100vh] pt-52 pb-40 bg-gradient-to-t from-[#11001c] to-slate-900 flex flex-col items-center">
      {loading ? (
        <div className="w-full h-full fixed left-0 top-0 bg-black/80 z-10 text-white flex flex-col items-center justify-center">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
          <h1 className="text-2xl mt-4">
            Just need a minute or two and I&apos;ll be done 👀
          </h1>
        </div>
      ) : null}
      <div className="text-purple-50 mt-2 text-center">
        <p className="text-4xl">😌</p>
        <br />
        Just write <i className="font-bold">anything</i> on your mind below.
        <br />
        I&apos;ll help you make sense of things.
      </div>
      <textarea
        className="mt-10 bg-[#11001c] w-[50%] min-h-52 max-h-64 text-white p-4 rounded-md"
        placeholder="Write anything..."
        value={content}
        onChange={(e) => setContent(e.target.value)}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={true}
      />
      <button
        className={clsx(
          "bg-purple-900 mt-12 px-4 py-4 font-medium transition rounded-md text-purple-200 uppercase tracking-widest text-sm",
          {
            "opacity-50 cursor-auto": content === "",
            "opacity-100 cursor-pointer hover:bg-purple-800": content !== "",
          }
        )}
        onClick={onClick}
        disabled={content === ""}
      >
        ✨ Create buckets
      </button>
      <p
        id="message"
        className="text-center w-4/6 text-purple-200 mt-24 mb-16 text-xl font-light"
      >
        {buckets.length !== 0 ? (
          <>
            {buckets[buckets.length - 1].things[0].thing}
            <br /> <br /> To help you out, I&apos;ve made some buckets below.
          </>
        ) : null}
      </p>
      {buckets.length !== 0 ? (
        <div className="flex flex-col gap-8 w-[90%] mt-12 text-purple-200 justify-center">
          {buckets.slice(0, -1).map((bucket, index) => (
            <details
              key={index}
              className="shadow-lg cursor-pointer px-4 w-full py-4 bg-[#11001c]"
            >
              <summary>{bucket.bucket}</summary>
              <div>
                {bucket.things.map((thing, key) => (
                  <div key={key} className="mt-4">
                    {thing.thing}
                    <br />
                    <span className="font-medium">
                      Suggestion: {thing.suggestedAction}
                    </span>
                  </div>
                ))}
              </div>
            </details>
          ))}
        </div>
      ) : null}
    </div>
  );
}
